@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,600;1,600&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: white;
}

.container {
  padding: 2rem;
  margin-bottom: 70;
}

:root {
  --gray-150: #3d4467;
}

.gray-50 {
  color: #c3c5ce;
}

.gray-90 {
  color: #5e6379;
}

.gray-100 {
  color: #545b79;
}

.default-color {
  color: #545b79;
}

.bg-black-100 {
  background-color: #eeeded;
  border: 0;
}

/* Button hover effect */
#back-to-top-btn:hover {
  background-color: #6b8b65; /* Change background color on hover */
}

h1 {
  font-family: "Quicksand", sans-serif;
}

h2 {
  font-family: "Quicksand", sans-serif;
}

h3 {
  font-family: "Quicksand", sans-serif;
}

h4 {
  font-family: "Quicksand", sans-serif;
}

h5 {
  font-family: "Quicksand", sans-serif;
}

h6 {
  font-family: "Quicksand", sans-serif;
}

.title {
  width: fit-content;
  max-width: 710px;
  margin: 0 0 30px;
  font-size: 48px;
  color: white;
}

.w-min-content {
  width: min-content;
}

/* Start swiper style */

.swiper {
  width: 100%;
  height: fit-content;
  padding-bottom: 35px;
}

.swiper-slide {
  font-size: 18px;
  margin-right: 15px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet {
  background-color: #43766c;
}

.swiper-button-next,
.swiper-button-prev {
  color: #43766c;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
  padding: 10px 15px;
  background-color: #ffffff;
  border-radius: 50%;
}

.swiper-button-next:after {
  margin-right: 15px;
}

/* End swiper style */

/* start Loading Style */

.loading::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: -10%;
  width: 150px;
  height: 150px;
  background-color: #356df6;
  border-radius: 50%;
  filter: blur(12rem);
  z-index: -999;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-filter: blur(12rem);
}

.loading div h1 {
  text-align: left;
  font-weight: 900;
  color: #597e52;
}

.loading div h2 {
  text-align: left;
  font-weight: 900;
  color: #e3f7f0;
}

.loading div button {
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #fbf6ee;
  text-align: center;
  background-color: #43766c;
}

.loading div button:hover {
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #fbf6ee;
  text-align: center;
  background-color: black;
}

.loading .features {
  width: inherit;
}

.loading div p {
  font-weight: 600;
}

.loading div .cards {
  position: relative;
  left: calc(55% - 60px);
  transform: translateX(-50%);
  width: fit-content;
  display: flex;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.imageHeader {
  height: 100%;
  width: 100%;
  /* background-color: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); */
}

#container-header {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.loading div .cards .card {
  width: fit-content;
}

.loading div .cards .card:first-child {
  left: -25px;
  transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
}

.loading div .cards .card:last-child {
  position: absolute;
  top: 25px;
  right: -120px;
  box-shadow: -3px -4px 11px 5px #00000059;
  transform: rotate(10deg);
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
}

.loading div .cards .card .card-body img {
  max-width: 190px;
}

/* End loading style */

/* Start header */

.header {
  margin-top: 70px;
  margin-bottom: 120px;
}

.header h4 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60.34px;
  text-align: center;
  margin-bottom: 40px;
}

.header .sub-heading {
  font-weight: 300;
}

.header .btn-miliki-sekarang:hover,
.header .btn-miliki-sekarang:focus {
  background-color: #16437e;
  color: #ffffff;
}

.header .btn-miliki-sekarang:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.header .btn-miliki-sekarang {
  align-items: center;
  background-color: #245a80;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.header .btn-miliki-sekarang:hover {
  background-color: #192d3b;
}

.header .btn-miliki-sekarang:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.header .btn-hubungi:hover,
.header .btn-hubungi:focus {
  background-color: #16437e;
  color: #ffffff;
}

.header .btn-hubungi:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.header .btn-hubungi {
  align-items: center;
  background-color: transparent !important;
  border: 1px #245a80 solid;
  border-radius: 100px;
  box-sizing: border-box;
  color: #245a80;
  cursor: pointer;
  display: inline-flex;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.header .btn-hubungi:hover {
  background-color: #192d3b;
  color: #245a80;
}

.header .btn-hubungi:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.header .font-link {
  font-size: 40px;
}


/* end header */

/* start porto */

.portofolio .sub-heading {
  font-weight: 300;
  font-size: 16px;
}

/* end porto */

/* start bonus */

.bonus {
  text-align: center;
}

.bonus .sub-heading {
  font-weight: 300;
  font-size: 16px;
}
.bonus .bonus-package {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 40px;
  padding: 50px 20px 50px 20px;
  /* gap: 50px; */
  border-radius: 10px;
  box-shadow: 0px 4px 40px 0px #0000001a;
}

.bonus .img-bonus {
  width: 70px;
  height: 70px;
}

.bonus h1 {
  font-weight: 500;
  font-size: 2.7rem;
  color: #245a80;
}

.bonus h5 {
  font-size: 12px;
  font-weight: 300 !important;
}

.bonus .btn-get-now:hover,
.bonus .btn-get-now:focus {
  background-color: #16437e;
  color: #ffffff;
}

.bonus .btn-get-now:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.bonus .btn-get-now {
  width: 50%;
  align-items: center;
  background-color: #245a80;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.bonus .btn-get-now:hover {
  background-color: #192d3b;
}

.bonus .btn-get-now:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

/* end bonus */

/* start package */

.package .sub-heading {
  font-weight: 300;
  font-size: 16px;
}

.package .package-template {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 40px;
  padding: 50px 20px 50px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 40px 0px #0000001a;
}

.package .btn-ask-package:hover,
.package .btn-ask-package:focus {
  background-color: #16437e;
  color: #ffffff;
}

.package .btn-ask-package:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.package .btn-ask-package {
  width: 100%;
  margin-top: 30px;
  align-items: center;
  background-color: #2f2f2f;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.package .btn-ask-package:hover {
  background-color: #192d3b;
}

.package .btn-ask-package:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

/* .package .tx-list-package {
  font-size: 14px;
  font-weight: 400;
} */

.package .tx-list-package {
  background-image: url("./images/package/ul-icon.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  display: block;
  background-size: 15px 15px;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 20px;
}

.package .tx-list-package-white {
  background-image: url("./images/package/ul-icon-white.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  display: block;
  font-size: 15px;
  margin-bottom: 20px;
  background-size: 15px 15px;
  line-height: 20px;
}

/* end package */

/* start about */

.about {
}

/* end about */

/* ask start */

.ask {
  margin-bottom: 100px;
}

.ask .sub-heading {
  font-weight: 300;
  font-size: 16px;
}

.ask .tx-form-label {
  font-size: 14px;
}

.ask .form-control {
  border-radius: 5px;
  border: 0.1px black solid;
  line-height: 30px;
}

.ask .btn-form-submit:hover,
.ask .btn-form-submit:focus {
  background-color: #16437e;
  color: #ffffff;
}

.ask .btn-form-submit:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.ask .btn-form-submit {
  align-items: center;
  background-color: #245a80;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.ask .btn-form-submit:hover {
  background-color: #192d3b;
}

.ask .btn-form-submit:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

/* ask end */

/* start service */

.service .sub-heading {
  font-weight: 300;
  font-size: 16px;
}

/* end service */

/* start hero */

.hero {
  margin-bottom: 120px;
}

.hero h6 {
  font-weight: 300;
  font-size: 1rem;
}

/* end hore */

/* Start media query */

/* start footer */

footer {
  padding-top: 20px;
  padding-inline: 15px;
  border-top: 0.1px solid black;
  background-color: transparent;
  text-align: center;
}

footer div p {
  color: white;
}

footer div h1 {
  text-align: center;
  color: white;
  font-weight: 900;
}

.buttonHub {
  text-align: left;
  font-weight: 900;
  color: white;
  border: 0;
  padding-inline: 30px;
  border-radius: 30px;
  padding-block: 15px;
  box-shadow: 0.5px 5px #000000;
  text-align: center;
  background-color: #43766c;
}

.line-top {
  border-top: 0.5px solid white;
}

footer div p {
  font-size: 14px;
}

/* end footer */

/* Small */
@media (max-width: 768px) {
  .header .btn-agen {
    width: 100%;
  }
  .header .btn-miliki-sekarang {
    width: 100%;
    min-width: 120px;
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .header .btn-hubungi {
    width: 100%;
    min-width: 120px;
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .imageHeader {
    object-fit: cover;
    height: 768px;
  }
  #container-header {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

/* medium */

@media (min-width: 768px) {
  .header .btn-miliki-sekarang {
    width: 100%;
    min-width: 120px;
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .header .btn-hubungi {
    width: 100%;
    min-width: 120px;
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .imageHeader {
    object-fit: cover;
    height: 768px;
  }
  #container-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

@media (max-width: 992px) {
  .header .btn-miliki-sekarang {
    width: 100%;
    min-width: 120px;
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .header .btn-hubungi {
    width: 100%;
    min-width: 120px;
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .imageHeader {
    object-fit: cover;
    height: 750px;
  }
  #container-header {
    position: absolute;
    top: 45%;
    left: 50%;
    color: #fff;
  }
}

/* Large */

@media (min-width: 1198px) {
  .header .btn-miliki-sekarang {
    width: 30%;
    min-width: 120px;
    padding: 0 25px;
  }
  .header .btn-hubungi {
    width: 30%;
    min-width: 120px;
    padding: 0 25px;
  }
  #container-header {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

/* End media query */
